import React, { useEffect, useRef, useState } from 'react';
import Utils from '../../../utils/utils';
import './Pricing.scss';

const INITIAL_SIZE = '100%';
const simulator = process.env.GATSBY_SIMULATOR_COMPANY;
const host = process.env.GATSBY_HOST;

function PricingFrame() {
  const [isLoading, setIsLoading] = useState(true);
  const [frameSrc, setFrameSrc] = useState('');
  const [frameSize, setFrameSize] = useState(INITIAL_SIZE);
  const frameRef = useRef(null);

  useEffect(() => {
    const handleMessage = (event) => {
      // Set auto-height to iframe
      if (event.data.height) {
        setFrameSize(event.data.height);
      }

      // Handle click on attribute tip and scroll to FAQ
      const scrollableNode = document.querySelector('.layout');
      if (event.data.scrollTo && scrollableNode && window.top) {
        scrollableNode.scrollTo(event.data.scrollTo);
        window.top.scrollTo(event.data.scrollTo); // responsive
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const onLoadFrame = () => setIsLoading(false);
  const params = typeof window !== 'undefined' && Utils.getQueryParam(location.search);

  useEffect(() => {
    const getFrameUrl = () => {
      const { location } = document;
      if (location.hostname === 'localhost') {
        return 'http://localhost:3000/license';
      }
      return `${simulator}/license`;
    };

    const { location } = document;
    const locationSearch = params;

    const queryParams = new URLSearchParams(locationSearch);
    queryParams.set('parentUrl', location.origin + location.pathname);
    queryParams.set('freeLicenseURL', host);
    queryParams.set('successURL', `${location.origin}/new-pricing/success`);
    const stringifedQueries = queryParams.toString();
    const frameBaseUrl = getFrameUrl();
    setFrameSrc(`${frameBaseUrl}${stringifedQueries ? `?${stringifedQueries}` : ''}`);
  }, [params]);

  return (
    <section className="product pricing-frame__container">
      <div className="product__header" />
      <div className="pricing-frame">
        <iframe
          style={{ opacity: Number(!isLoading) }}
          ref={frameRef}
          className="pricing-frame__iframe"
          height={frameSize}
          title="Corezoid Pricing and License Selection"
          name="corezoid"
          onLoad={onLoadFrame}
          src={frameSrc}
          frameBorder={0}
        />
      </div>
    </section>
  );
}

export default PricingFrame;
